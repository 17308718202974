import { stat } from "fs";
import { createReducer } from "reduxsauce";
import Immutable from "seamless-immutable";
import { SystemBankTypes } from "./system-banks-actions";

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
    fetchingSystemBankList: null,
    fetchingSystemBank: null,
    fetchingSystemBanksCount: null,
    uploadingLogo: false,
    disablingSystemBank: null,
    fetchingProviderBanks: null,
    fetchingProviders: null,
    fetchingBanks: null,
    linkingBankToProvider: false,
    unlinkingBankToProvider: false,

    systemBankList: null,
    systemBank: null,
    systemBanksCount: null,
    logoSuccess: null,
    providerBanks: null,
    providers: null,
    banksPerProvider: null,
    link: null,
    unlink: null,

    errorSystemBankList: null,
    errorSystemBank: null,
    errorSystemBankCount: null,
    errorLogo: null,
    errordisablingSystemBank: null,
    errorFetchingProviderBanks: null,
    errorFetchingProviders: null,
    errorFetchingBanks: null,
    errorLinking: null,
    errorUnlinking: null,

})

/* ------------- Reducers ------------- */

// request the system bank list from an api
export const systemBankListRequest = (state) => { return { ...state, fetchingSystemBankList: true, systemBankList: null } }

// request the system bank  from an api
export const systemBankRequest = (state) => { return { ...state, fetchingSystemBank: true, systemBank: null } }

// request the data from an api
export const systemBanksCountRequest = (state) => { return { ...state, fetchingSystemBanksCount: true, systemBanksCount: null } }

// uploading logo
export const logoUpload = (state) => { return { ...state, uploadingLogo: true, logoSuccess: null, errorLogo: null } }

//disabling system bank 
export const disableSystemBankRequest = (state) => { return { ...state, disablingSystemBank: true } }

// fetching provider banks from api 
export const providerBanksRequest = (state) => { return { ...state, fetchingProviderBanks: true } }

// fetching providers from api 
export const providersRequest = (state) => { return { ...state, fetchingProviders: true } }

// fetching banks per provider from api 
export const bankPerProviderRequest = (state) => { return { ...state, fetchingBanks: true } }

//linking banks to provider from api
export const linkRequest = (state) => { return { ...state, linkingBankToProvider: true, link: null } }

//unlinking banks to provider from api
export const unlinkRequest = (state) => { return { ...state, unlinkingBankToProvider: true, unlink: null } }

// successful api lookup for system bank list 
export const systemBankListSuccess = (state, action) => {
    const { systemBankList } = action
    return {
        ...state,
        fetchingSystemBankList: false,
        errorSystemBankList: null,
        systemBankList
    }
}

// successful api lookup for system bank  
export const systemBankSuccess = (state, action) => {
    const { systemBank } = action
    return {
        ...state,
        fetchingSystemBank: false,
        errorSystemBank: null,
        systemBank
    }
}

// succesful api lookup for system bank count
export const systemBanksCountSuccess = (state, action) => {
    const { count } = action;
    return {
        ...state,
        fetchingSystemBanksCount: false,
        errorSystemBankCount: null,
        systemBanksCount: count
    }
};

// successful logo upload
export const logoUploadSuccess = (state, action) => {
    const { logo } = action;
    return {
        ...state,
        uploadingLogo: false,
        errorLogo: null,
        logoSuccess: logo
    }
};

//successful disable
export const disableSystemBankSuccess = (state) => {
    return {
        ...state, disablingSystemBank: false, errordisablingSystemBank: null
    }
}

//successful fetching provider banks
export const providerBanksSuccess = (state, action) => {
    const { providerBanks } = action
    return {
        ...state, fetchingProviderBanks: false, errorFetchingProviderBanks: null, providerBanks
    }
}

//successful fetching providers
export const providersSuccess = (state, action) => {
    const { providers } = action
    return {
        ...state, fetchingProviders: false, errorFetchingProviders: null, providers
    }
}

//successful fetching banks per providers
export const bankPerProviderSuccess = (state, action) => {
    const { banks } = action
    return {
        ...state, fetchingBanks: false, errorFetchingBanks: null, banksPerProvider: banks
    }
}

//successful linking banks to providers
export const linkSuccess = (state) => {
    return {
        ...state, linkingBankToProvider: false, errorLinking: null
    }
}

//successful unlinking banks to providers
export const unlinkSuccess = (state) => {
    return {
        ...state, unlinkingBankToProvider: false, errorUnlinking: null
    }
}


// something went wrong fetching system bank list
export const systemBankListFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingSystemBankList: false,
        errorSystemBankList: error,
        systemBankList: null
    }
}

// something went wrong linking banks to provider
export const linkFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        linkingBankToProvider: false,
        errorLinking: error,
        link: null
    }
}

// something went wrong unlinking banks to provider
export const unlinkFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        unlinkingBankToProvider: false,
        errorUnlinking: error,
        unlink: null
    }
}


// something went wrong fetching system bank 
export const systemBankFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingSystemBank: false,
        errorSystemBank: error,
        systemBank: null
    }
}

// something went wrong fetching system bank count
export const systemBanksCountFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        fetchingSystemBanksCount: false,
        errorSystemBankCount: error,
        systemBanksCount: null
    }
}

export const logoUploadFailure = (state, action) => {
    const { error } = action
    return {
        ...state,
        uploadingLogo: false,
        errorLogo: error,
        logoSuccess: null
    }
};

// failure disabling
export const disableSystemBankFailure = (state, action) => {
    const { error } = action
    return {
        ...state, disablingSystemBank: false, errordisablingSystemBank: error
    }
};

// error fecthing provider banks
export const providerBanksFailure = (state, action) => {
    const { error } = action
    return {
        ...state, fetchingProviderBanks: false, errorFetchingProviderBanks: error
    }
}

// error fecthing providers
export const providersFailure = (state, action) => {
    const { error } = action
    return {
        ...state, fetchingProviders: false, errorFetchingProviders: error
    }
}

// error fecthing banks per provider
export const bankPerProviderFailure = (state, action) => {
    const { error } = action
    return {
        ...state, fetchingBanks: false, errorFetchingBanks: error
    }
}


/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
    [SystemBankTypes.SYSTEM_BANK_LIST_REQUEST]: systemBankListRequest,
    [SystemBankTypes.SYSTEM_BANK_REQUEST]: systemBankRequest,
    [SystemBankTypes.SYSTEM_BANKS_COUNT_REQUEST]: systemBanksCountRequest,
    [SystemBankTypes.LOGO_REQUEST]: logoUpload,
    [SystemBankTypes.DISABLE_SYSTEM_BANK_REQUEST]: disableSystemBankRequest,
    [SystemBankTypes.PROVIDER_BANKS_REQUEST]: providerBanksRequest,
    [SystemBankTypes.PROVIDERS_REQUEST]: providersRequest,
    [SystemBankTypes.BANK_PER_PROVIDER_REQUEST]: bankPerProviderRequest,
    [SystemBankTypes.LINK_REQUEST]: linkRequest,
    [SystemBankTypes.UNLINK_REQUEST]: unlinkRequest,

    [SystemBankTypes.SYSTEM_BANK_LIST_SUCCESS]: systemBankListSuccess,
    [SystemBankTypes.SYSTEM_BANK_SUCCESS]: systemBankSuccess,
    [SystemBankTypes.SYSTEM_BANKS_COUNT_SUCCESS]: systemBanksCountSuccess,
    [SystemBankTypes.LOGO_SUCCESS]: logoUploadSuccess,
    [SystemBankTypes.DISABLE_SYSTEM_BANK_SUCCESS]: disableSystemBankSuccess,
    [SystemBankTypes.PROVIDER_BANKS_SUCCESS]: providerBanksSuccess,
    [SystemBankTypes.PROVIDERS_SUCCESS]: providersSuccess,
    [SystemBankTypes.BANK_PER_PROVIDER_SUCCESS]: bankPerProviderSuccess,
    [SystemBankTypes.LINK_SUCCESS]: linkSuccess,
    [SystemBankTypes.UNLINK_SUCCESS]: unlinkSuccess,

    [SystemBankTypes.SYSTEM_BANK_LIST_FAILURE]: systemBankListFailure,
    [SystemBankTypes.SYSTEM_BANK_FAILURE]: systemBankFailure,
    [SystemBankTypes.SYSTEM_BANKS_COUNT_FAILURE]: systemBanksCountFailure,
    [SystemBankTypes.LOGO_FAILURE]: logoUploadFailure,
    [SystemBankTypes.DISABLE_SYSTEM_BANK_FAILURE]: disableSystemBankFailure,
    [SystemBankTypes.PROVIDER_BANKS_FAILURE]: providerBanksFailure,
    [SystemBankTypes.PROVIDERS_FAILURE]: providersFailure,
    [SystemBankTypes.BANK_PER_PROVIDER_FAILURE]: bankPerProviderFailure,
    [SystemBankTypes.LINK_FAILURE]: linkFailure,
    [SystemBankTypes.UNLINK_FAILURE]: unlinkFailure,

})