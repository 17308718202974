import React from "react";
import { routes } from "./routes/";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/Store";
import { PersistGate } from 'redux-persist/integration/react'
import Loading from './views/ui-components/Loading'
import "react-datetime/css/react-datetime.css";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoginScreen from "./views/authentication/LoginScreen";
import AccessDenied from "./views/authentication/AccessDenied";
import FullLayout from "./layouts/FullLayout.js";
import ImplicitCallback from "./layouts/ImplicitCallBack"
import AppRoute from "./AppRoute";

const App = () => {

  return (
    <Provider store={store}>
      <PersistGate loading={<Loading />} persistor={persistor}>
        <Router>
          <Switch>
            <Route exact path="/authentication/access-denied" component={AccessDenied} />
            <Route path="/implicit/callback" component={ImplicitCallback} />
            <Route exact path="/" component={LoginScreen} />;

            {routes.map((prop, key) => {
              return (
                <AppRoute path={prop.path} component={withAuthenticationRequired(prop.component,
                  { onRedirecting: Loading, returnTo: prop.path })} layout={FullLayout} key={key}
                />
              );
            })
            }
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
};
export default App;