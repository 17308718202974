import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import Spinner from "./views/spinner/Spinner";
import { ErrorBoundary } from 'react-error-boundary';
import App from './app';
import { ErrorFallback } from "./components/error-handlers/ErrorFallback";
import "./data";
import './i18n';
import "./assets/scss/style.css";

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_CLIENTID}
      redirectUri={process.env.REACT_APP_AUTH_REDIRECTURI}
      audience={process.env.REACT_APP_AUTH_AUDIENCE}
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
      </ErrorBoundary>
    </Auth0Provider>
  </Suspense>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}