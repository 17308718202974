import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "reactstrap";
import { useTranslation } from 'react-i18next';
import Loading from '../ui-components/Loading';
import { useAuth0 } from "@auth0/auth0-react";
import { setAuthToken } from "../../services/api";
import { useHistory } from "react-router-dom";
import AuthorizationActions from "../../redux/authorization/authorization-actions";
import { useDispatch } from "react-redux";

import img2 from "../../assets/images/big/auth-bg.jpg";

const sidebarBackground = {
    backgroundImage: "url(" + img2 + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
};

const LoginScreen = (props) => {
    const appAudience = process.env.REACT_APP_AUTH_AUDIENCE;

    const { t } = useTranslation();
    const history = useHistory();

    //dispatch actions to redux
    const dispatch = useDispatch();
    const setAuthorizationStatus = (status) => dispatch(AuthorizationActions.isAuthorizedUser(status))

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);
    const [isAuthPending, setIsAuthPending] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const { user, loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();


    const authText = t("authentication.auth_text")

    useEffect(() => {

        if (isLoading) {
            setIsAuthPending(true);
        }
        if (isAuthenticated) {
            setIsAuthPending(false);
            setIsUserAuthenticated(true);
            setUserInfo(user);
            const getUserMetadata = async () => {
                try {
                    await getAccessTokenSilently()
                        .then((data) => {
                            setAuthToken(data)
                        })
                } catch (e) {
                    console.log(e.message);
                }
            };
            getUserMetadata();
        } else if (!isAuthenticated) {
            setIsAuthPending(false);
            setIsUserAuthenticated(false);
            setUserInfo(null);
        }
    }, [isAuthenticated, isLoading, user, getAccessTokenSilently, history])

    if (isAuthPending) {
        return <div className='align-items-center d-flex'>{authText}</div>
    }

    if (!isUserAuthenticated) {
        return (
            <div
                className="auth-wrapper  align-items-center d-flex"
                style={sidebarBackground}
            >
                {/*--------------------------------------------------------------------------------*/}
                {/*LoginScreen Card*/}
                {/*--------------------------------------------------------------------------------*/}
                <div className="container">
                    <div>
                        <Row className="no-gutters justify-content-center">
                            <Col md="6" lg="4" className="bg-dark text-white">
                                <div className="p-4">
                                    <h2 className="display-5">
                                        {t("authentication.hi")}
                                        <br />
                                        <span className="text-cyan font-bold">{t("authentication.admin")}</span>
                                    </h2>
                                    <p className="op-5 mt-4">
                                        {t("authentication.desciption")}
                                    </p>
                                    <p className="mt-5">{t("authentication.tagline")}</p>
                                </div>
                            </Col>
                            <Col md="6" lg="4" className="bg-white">
                                <div className="p-4">
                                    <h3 className="font-medium pb-5">{t("authentication.signin_text")}</h3>
                                    <Row className="mb-3 mt-5">
                                        <Col xs="12">
                                            <Button color="primary" onClick={() => { loginWithRedirect() }} size="lg" block >
                                                {t("authentication.log_in")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }
    if (isUserAuthenticated) {
        //check for admin access here
        const roleNode = `${appAudience}/roles`;

        const superUserRole = userInfo?.[roleNode].find(element => element === 'Superuser')
        const staffRole = userInfo?.[roleNode].find(element => element === 'Staff')

        if (superUserRole || staffRole) {
            setAuthorizationStatus(true);
            history.push("/settlements")
        } else {
            setAuthorizationStatus(false);
            history.replace("/authentication/access-denied")
        }
    }
    return <Loading />
};

export default LoginScreen;