import React from 'react';
import { Route } from "react-router-dom";
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const AppRoute = ({
    component: Component,
    layout: Layout,
    path,
    name,
    ...rest
}) => {
    const history = useHistory();

    //authorization status from redux store
    const authorizationStatus = useSelector(state => state.authorization.status);

    Layout = (Layout === undefined) ? props => (<>{props.children}</>) : Layout;
    return (
        <Route
            exact
            {...rest}
            path={path}
            render={props => {
                if (authorizationStatus) {
                    return (
                        <Layout {...props} >
                            <Component {...props} />
                        </Layout>
                    )
                } else {
                    history.replace('/authentication/access-denied')
                }
            }}
        />
    );
}
export default AppRoute;