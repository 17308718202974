
import React, { useState, useEffect, Suspense } from "react";
import { useSelector } from "react-redux";
import Header from "./layout-components/header/Header";
import Sidebar from "./layout-components/sidebar/Sidebar";
import Footer from "./layout-components/footer/Footer";
import ThemeRoutes from "../routes/Router";
import Spinner from "./../views/spinner/Spinner";
import { useAuth0 } from "@auth0/auth0-react";
import { setAuthToken } from "../services/api"

export default (props) => {
  const [width, setWidth] = useState(window.innerWidth);
  const settings = useSelector((state) => state.settings);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const updateDimensions = () => {
      let element = document.getElementById("main-wrapper");
      if (element) {
        setWidth(window.innerWidth);
        switch (settings.activeSidebarType) {
          case "full":
          case "iconbar":
            if (width < 1170) {
              element.setAttribute("data-sidebartype", "mini-sidebar");
              element.classList.add("mini-sidebar");
            } else {
              element.setAttribute(
                "data-sidebartype",
                settings.activeSidebarType
              );
              element.classList.remove("mini-sidebar");
            }
            break;
          case "overlay":
            if (width < 767) {
              element.setAttribute("data-sidebartype", "mini-sidebar");
            } else {
              element.setAttribute(
                "data-sidebartype",
                settings.activeSidebarType
              );
            }
            break;
          default:
        }
      }
    };
    if (isAuthenticated) {
      const getUserMetadata = async () => {
        try {
          await getAccessTokenSilently()
            .then((data) => {
              setAuthToken(data)
            })
        } catch (e) {
          console.log(e.message);
        }
      };
      getUserMetadata();
    }
    if (document.readyState === "complete") {
      updateDimensions();
    }
    window.addEventListener("load", updateDimensions.bind(null));
    window.addEventListener("resize", updateDimensions.bind(null));
    return () => {
      window.removeEventListener("load", updateDimensions.bind(null));
      window.removeEventListener("resize", updateDimensions.bind(null));
    };
  }, [settings.activeSidebarType, width, isAuthenticated, getAccessTokenSilently]);
  return (
    <div
      id="main-wrapper"
      dir={settings.activeDir}
      data-theme={settings.activeTheme}
      data-layout={settings.activeThemeLayout}
      data-sidebartype={settings.activeSidebarType}
      data-sidebar-position={settings.activeSidebarPos}
      data-header-position={settings.activeHeaderPos}
      data-boxed-layout={settings.activeLayout}
    >
      {/*--------------------------------------------------------------------------------*/}
      {/* Header                                                                         */}
      {/*--------------------------------------------------------------------------------*/}
      <Header />
      {/*--------------------------------------------------------------------------------*/}
      {/* Sidebar                                                                        */}
      {/*--------------------------------------------------------------------------------*/}
      <Sidebar {...props} routes={ThemeRoutes} />
      {/*--------------------------------------------------------------------------------*/}
      {/* Page Main-Content                                                              */}
      {/*--------------------------------------------------------------------------------*/}
      <div className="page-wrapper d-block">
        <div className="page-content container-fluid">
          <Suspense fallback={<Spinner />}>
            {props.children}
          </Suspense>
        </div>
        <Footer />
      </div>
      {/*--------------------------------------------------------------------------------*/}
      {/* Customizer from which you can set all the Layout Settings                      */}
      {/*--------------------------------------------------------------------------------*/}
    </div>
  );
}
// };