import React, { useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { setAuthToken } from "../services/api"
import { useHistory } from "react-router-dom";
import Loading from '../views/ui-components/Loading';


const ImplicitCallback = () => {
    const { isAuthenticated, isLoading, user, getAccessTokenSilently } = useAuth0();
    const history = useHistory()

    useEffect(() => {

        if (isLoading) {
        } else if (isAuthenticated) {
            const getUserMetadata = async () => {
                try {
                    await getAccessTokenSilently()
                        .then((data) => {
                            setAuthToken(data)
                        })
                        .then(() => {
                            history.push("/")
                        })
                } catch (e) {
                    console.log(e.message);
                }
            };
            getUserMetadata();
        }
    }, [isAuthenticated, isLoading, user, getAccessTokenSilently]);

    return (
        <div>
            <Loading/>
        </div>
    );
}

export default ImplicitCallback;
